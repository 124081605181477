import React from 'react'
import { injectIntl } from '../../util/reactIntl'
import css from './ListingPage.module.css'

import ProfileIcon from '../../assets/icons/profile.png'
import Spacer, { HORIZONTALL_AXIS } from '../../components/Spacer/Spacer'
import { NamedLink } from '../../components';
import { compose } from 'redux'


const SectionOfferedBy = props => {
  const { authorLink, phoneNumber, isAuthenticated, intl } = props


  const renderCallButton = () => (
    <a className={css.phoneButton} href={`tel:${phoneNumber}`}>
      {phoneNumber}
    </a>
  )

  const renderLoginButton = () => (
    <NamedLink name="LoginPage"className={css.phoneButton}>
      {intl.formatMessage({ id: 'listing_page.offered_by.phone_number' })}
    </NamedLink>
  )

  const renderPhoneNumberSection = () => {
    if (!phoneNumber) return

    return (
      <div className={css.authorWrapper}>
        {isAuthenticated ? renderCallButton() : renderLoginButton()}
    </div>
    )
  }

  return (
    <div className={css.sectionOfferedBy}>
      <div className={css.listingPageGroupLabelWrapper}>
        <label className={css.listingPageGroupLabel}>
          {intl.formatMessage({ id: 'listing_page.offered_by.label' })}
        </label>
      </div>
      <div className={css.authorWrapper}>
        <img className={css.icon} src={ProfileIcon}/>
        <Spacer axis={HORIZONTALL_AXIS} size={16}/>
        <span className={css.authorSpan}>
          {intl.formatMessage({ id: 'listing_page.sold_by' }, { name: authorLink })}
        </span>
      </div>
      {renderPhoneNumberSection()}
    </div>

  )
}

export default compose(injectIntl)(SectionOfferedBy)
