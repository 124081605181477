import React from 'react';
import Collapsible from '../../components/Collapsible/Collapsible';
import css from './ListingPage.module.css';

const SectionDetailsMaybe = props => {
  const { publicData, customConfig, intl } = props;
  const { listing, filters } = customConfig || {};

  if (!publicData || !customConfig || !listing?.enumFieldDetails) {
    return null;
  }

  const pickExtendedData = filterConfig => (rows, key) => {
    const publicDataValue = publicData[key];
    if (publicDataValue) {
      const filterIfItExists = filterConfig.find(f => f.id === key);
      const filterOptions = filterIfItExists?.config?.options || [];

      const translationGroup = filterIfItExists?.translationGroup
      const skipItemPageTranslations = filterIfItExists?.skipItemPageTranslations

      const value = translationGroup && !skipItemPageTranslations 
        ? intl.formatMessage({ id: `${translationGroup}.${publicDataValue}` })
        : filterOptions.find(o => o.key === publicDataValue)?.label || publicDataValue

      const label = filterIfItExists?.label || `${key.charAt(0).toUpperCase()}${key.slice(1)}`;

      return rows.concat({ key, value, label });
    }
    return rows;
  };

  const existingExtendedData = listing?.enumFieldDetails.reduce(pickExtendedData(filters), []);

  return existingExtendedData ? (
    <div className={css.sectionDetails}>
      <Collapsible label={intl.formatMessage({ id: 'listing_page.details.label' })}>
        <ul className={css.details}>
          {existingExtendedData.map(detail => (
            <li key={detail.key} className={css.detailsRow}>
              <span className={css.detailLabel}>{intl.formatMessage({ id: `config.labels.${detail.key}` })}</span>
              <span className={css.detailValue}>{detail.value}</span>
            </li>
          ))}
        </ul>
      </Collapsible>
    </div>
  ) : null;
};

export default SectionDetailsMaybe;
