import React from 'react';

import css from './ListingPage.module.css';

import { Button } from '../../components';
import Spacer, { HORIZONTALL_AXIS } from '../../components/Spacer/Spacer';

import LikeBlankIcon from '../../assets/icons/hearts/blank-24.png'
import LikeFilledIcon from '../../assets/icons/hearts/filled-24.png'

const SectionHeading = props => {
  const {
    priceTitle,
    formattedPrice,
    richTitle,
    isOwnListing,
    onContactUser,
    intl,
    author,
    listing,
    addUserFavorite,
    removeUserFavorite,
    isLiked,
    listingId,
    currentUserId,
    listingType
  } = props;

  const handleLikeClick = (e) => {
    e.stopPropagation();
    e.preventDefault();

    isLiked ? removeUserFavorite(listingId) : addUserFavorite(listingId, listingType)
  }

  const contactButton = !isOwnListing ? (
    <Button
      rootClassName={css.contactSellerButton}
      onClick={() => onContactUser(author, listing)}
    >
      {intl.formatMessage({ id: 'listing_page.contact_seller' })}
    </Button>
  ) : null

  const likeButton = !isOwnListing && currentUserId ? (
    <Button
      rootClassName={css.likeListingButton}
      onClick={(e) => handleLikeClick(e)}
    >
      {
        isLiked
          ? <img className={css.likeIcon} src={LikeFilledIcon}/>
          : <img className={css.likeIcon} src={LikeBlankIcon}/>
      }
      <Spacer axis={HORIZONTALL_AXIS} size={6}/>
      {
        isLiked
          ? intl.formatMessage({ id: 'listing_page.remove_from_favorites' })
          : intl.formatMessage({ id: 'listing_page.add_to_favorites' })
      }
    </Button>
  ) : null

  return (
    <div className={css.sectionHeading}>
      <div className={css.heading}>
        {richTitle}
      </div>
      <Spacer size={16}/>
      <div className={css.desktopPriceContainer}>
        <div className={css.priceContainer}>
          <div className={css.priceValue} title={priceTitle}>
            {formattedPrice}
          </div>
        </div>

        <Spacer size={40}/>

        <div className={css.desktopHeadingActions}>
          {contactButton}
          <Spacer axis={HORIZONTALL_AXIS} size={16}/>
          {likeButton}
        </div>
        <Spacer size={16}/>
      </div>
    </div>
  );
};

export default SectionHeading;
