import React, { useState } from 'react'
import PropTypes from 'prop-types'

import { propTypes } from '../../../util/types'
import { injectIntl, intlShape } from '../../../util/reactIntl'
import { IconArrowHead } from '../../../components'

import css from './ListingImageGallery.module.css'

import ImagePreview from '../../../components/ImagePreview/ImagePreview'
import classNames from 'classnames'
import Spacer, { HORIZONTALL_AXIS, MEDIUM_SPACER, SMALL_SPACER } from '../../../components/Spacer/Spacer'

const ListingImageGallery = props => {
  const { images } = props

  const [isImagePreviewOpen, setIsImagePrieviewOpen] = useState(false)
  const [imageIndex, setImageIndex] = useState(0)
  const itemSrcRefs = []

  const imagesSrc = images
    .filter(image => image !== undefined)
    .map(image => image.attributes.variants['scaled-large'].url)
  const imageSrcCount = imagesSrc.length - 1

  const setItemRef = (elem, index) => {
    itemSrcRefs[index] = elem
  }

  const updateCurrentSlideIndex = (step) => {
    if(imageIndex + step < 0) {
      setImageIndex(imageSrcCount)
      itemSrcRefs[imageSrcCount].scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: "end" })
    } else if(imageIndex + step > imageSrcCount) {
      setImageIndex(0)
      itemSrcRefs[0].scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: "end" })
    } else {
      setImageIndex(imageIndex + step)
      itemSrcRefs[imageIndex + 1].scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: "end" })
    }
  }

  return (
    <div className={css.root}>
      {isImagePreviewOpen ? (
        <ImagePreview
          currentIndex={imageIndex}
          imagesSrc={imagesSrc}
          onClose={() => setIsImagePrieviewOpen(false)}
        />
      ) : <></> }
      <div className={css.imagePreviewWrapper}>
        <>
          <div className={css.navLeft} onClick={() => updateCurrentSlideIndex(-1)}>
            <div className={css.navArrowWrapper}>
              <IconArrowHead direction="left" size="big" />
            </div>
          </div>
          <div className={css.navRight} onClick={() => updateCurrentSlideIndex(1)}>
            <div className={css.navArrowWrapper}>
              <IconArrowHead direction="right" size="big" />
            </div>
          </div>
        </>

        {imagesSrc.map((src, index) => {
          const imageClassName = index === imageIndex ? classNames(css.imagePreviewImage, css.selectedImage) : css.imagePreviewImage

          return (
            <img
              key={index}
              className={imageClassName} 
              src={src}
              onClick={() => setIsImagePrieviewOpen(true)}
            />
          )
        })}
      </div>
      <Spacer size={MEDIUM_SPACER}/>
      <div className={css.imageCarouselPreviewRoot}>
        {imagesSrc.map((src, index) => (
          <div key={`carousel_wrap_${index}`} className={css.imageCarouselImageWrapper} ref={(e) => setItemRef(e, index)}>
            <img
              key={`carousel_${index}`}
              className={css.imageCarouselImage}
              src={src}
              onClick={() => setImageIndex(index)}
            />
            <Spacer size={SMALL_SPACER} axis={HORIZONTALL_AXIS}/>
          </div>
        ))}
      </div>
    </div>
    
  )
}

const { arrayOf } = PropTypes

ListingImageGallery.propTypes = {
  images: arrayOf(propTypes.image).isRequired,
  intl: intlShape.isRequired,
}

export default injectIntl(ListingImageGallery)
