import React from 'react'
import css from './ListingPage.module.css'

import LocationPinIcon from '../../assets/icons/location/pin.png'
import LocationShippingIcon from '../../assets/icons/location/shipping.png'
import Spacer, { HORIZONTALL_AXIS } from '../../components/Spacer/Spacer'
import { injectIntl } from '../../util/reactIntl'
import { compose } from 'redux';

const SectionBikeLocation = props => {
  const { location, shipping, intl } = props;

  return (
    <div className={css.sectionBikeLocation}>
      <div className={css.listingPageGroupLabelWrapper}>
        <label className={css.listingPageGroupLabel}>
          {intl.formatMessage({ id: 'listing_page.location.label' })}
        </label>
      </div>
      <div className={css.bikeLocationWrapper}>
        <div className={css.bikeLocationCell}>
          <img className={css.bikeLocationIcon} src={LocationPinIcon}/>
          <Spacer axis={HORIZONTALL_AXIS} size={16}/>
          <span className={css.bikeLocationLabel}>{location?.city}, {location?.countryCode}</span>
        </div>
        <div className={css.bikeLocationCell}>
          <img className={css.bikeLocationIcon} src={LocationShippingIcon}/>
          <Spacer axis={HORIZONTALL_AXIS} size={16}/>
          <span className={css.bikeLocationLabel}>{shipping}</span>
        </div>
      </div>
    </div>
  )
}

export default compose(injectIntl)(SectionBikeLocation)
