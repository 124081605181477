import React, { useState } from 'react'
import css from './Collapsible.module.css'

import UpArrowIcon from '../../assets/icons/arrows/up.png'
import DownArrowIcon from '../../assets/icons/arrows/down.png'

const Collapsible = props => {
  const { label, children } = props

  const [open, setOpen] = useState(false)

  const toggle = () => {
    setOpen(!open)
  }

  return (
    <>
      <div className={css.labelWrapper} onClick={toggle}>
        <label className={css.label}>{label}</label>
        <img className={css.arrowIcon} src={open ? UpArrowIcon : DownArrowIcon}/>
      </div>
      {open && (
        <div className={css.content}>
          {children}
        </div>
      )}
    </>
  )
}

export default Collapsible
